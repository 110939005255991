import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import React from 'react'

import styles from './styles.module.scss'

const Background: React.FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <div {...props} className={clsx(styles.background, className)}>
      {children}
    </div>
  )
}

export default Background
