import clsx from 'clsx'
import type { FC } from 'react'
import React, { useEffect } from 'react'

import { EventName, sendEvent } from '~/apis/tracking'
import image404 from '~/public/images/404.png'

import styles from './styles.module.scss'

const WentWrong: FC = () => {
  useEffect(() => {
    sendEvent({
      type: 'screen',
      data: {
        ref: EventName.SIGN_IN_START,
        event: EventName.INVALID,
        screen: EventName.INVALID,
        screen_properties: {
          error: '500 - Something went wrong',
        },
      },
    })
  }, [])

  return (
    <div className="flex items-center justify-center">
      <div className="flex w-full max-w-6xl flex-col items-center p-16 lg:flex-row lg:justify-between">
        <div className="flex flex-col justify-center text-center lg:text-left">
          <h4 className="mb-16 text-h4 font-bold leading-44">
            Something went wrong
          </h4>
          <p className="mb-24">Please try again or refresh the page.</p>
        </div>
        <img
          className={clsx('select-none', styles.image)}
          width={250}
          height={250}
          src={image404}
          alt="404"
        />
      </div>
    </div>
  )
}
export default WentWrong
