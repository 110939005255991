import { PUBLIC_AUTH_SERVER_URL } from '@account-service/core/envs'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'

import type { ErrorResponse } from './types'

export class EmptyPayloadError extends Error {
  name = 'EmptyPayload.'

  message = 'Empty payload.'
}

export type TRequestConfig = AxiosRequestConfig & {
  ignoreError?: boolean
}

export const createAxiosInstant = (baseURL?: string) => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
}

export const client = createAxiosInstant(PUBLIC_AUTH_SERVER_URL)

export const request = async <T>(options: TRequestConfig): Promise<T> => {
  const { ignoreError = false, ...axiosOptions } = options

  try {
    const { data } = await client(axiosOptions)

    if (data) return data

    return await Promise.reject(new EmptyPayloadError())
  } catch (error) {
    if (ignoreError) return undefined as T

    return Promise.reject(
      (error as AxiosError)?.response?.data as ErrorResponse,
    )
  }
}

export default request
