import { genTimestamp, randomUUID } from '@account-service/core'
import {
  PUBLIC_TRACKING_API_KEY,
  PUBLIC_TRACKING_API_URL,
} from '@account-service/core/envs'

import { getClientId, getSessionId, getUserId } from '~/utils/storage'

import request from './request'

const TRACKING_URL = PUBLIC_TRACKING_API_URL
const API_KEY = btoa(`${PUBLIC_TRACKING_API_KEY}:`)

export enum EventName {
  // User starts sign in
  SIGN_IN_START = 'Sign in start',
  // User clicks on a methods
  SELECT_METHOD = 'Select methods',
  // User clicks on 2FA
  TWO_FA_STARTS = '2FA starts',
  // User successfully input on 2FA
  TWO_FA_INPUT = '2FA input',
  // Invalid sign in response code
  SIGN_IN_FAILED = 'Sign in failed',
  // Success sign in response code
  SIGN_IN_SUCCESS = 'Sign in success',
  // Send user id when login success
  IDENTIFY = 'identify',

  // Screens
  SIGN_IN = 'Sign in',
  CONSENT = 'Consent',
  INVALID = 'Invalid',
  SUCCESS = 'Success',
  DASHBOARD = 'Dashboard',
}

export interface TDictionary<TValue> {
  [id: string]: TValue
}

export interface TBaseEventData {
  event: EventName | string
  ref?: EventName | string
  uuid?: string
  timestamp?: string
  session_id?: string
  offset?: number
  user_id?: string
}

export interface TIdentifyEventData extends TBaseEventData {
  build_version?: string
  device_name?: string
  device_id?: string
  platform_name?: string
  platform_version?: string
  internet_type?: string
  user_properties?: TDictionary<any>
}

export interface TTrackEventData extends TBaseEventData {
  action?: string
  action_properties?: TDictionary<any>
}

export interface TScreenEventData extends TBaseEventData {
  screen?: string
  screen_properties?: TDictionary<any>
}

export type TEventType = 'identify' | 'track' | 'screen'

export interface TEvent {
  type: TEventType
  data: TIdentifyEventData | TTrackEventData | TScreenEventData
}

const addMetadataToEvent = (event: TEvent): TEvent => {
  return {
    ...event,
    data: {
      ...event.data,
      user_properties:
        event.type === 'identify'
          ? {
              client_id: getClientId(),
              ...((event.data as TIdentifyEventData).user_properties ?? {}),
            }
          : undefined,
      screen_properties:
        event.type === 'screen'
          ? {
              client_id: getClientId(),
              ...((event.data as TScreenEventData).screen_properties ?? {}),
            }
          : undefined,
      action_properties:
        event.type === 'track'
          ? {
              client_id: getClientId(),
              ...((event.data as TTrackEventData).action_properties ?? {}),
            }
          : undefined,
      uuid: randomUUID(),
      user_id: getUserId(),
      session_id: getSessionId(),
      timestamp: genTimestamp(),
    },
  }
}

export const sendEvent = (event: TEvent | TEvent[]) => {
  const formattedEvents = Array.isArray(event)
    ? event.map(eventItem => addMetadataToEvent(eventItem))
    : [addMetadataToEvent(event)]

  request({
    ignoreError: true,
    baseURL: TRACKING_URL,
    headers: {
      Authorization: `Basic ${API_KEY}`,
    },
    method: 'POST',
    data: JSON.stringify({
      events: formattedEvents,
    }),
  })
}
