import '~/styles/global.scss'

import { CaptchaProvider } from '@account-service/core'
import {
  PUBLIC_AUTH_SERVER_URL,
  PUBLIC_CAPTCHA_API_KEY,
  PUBLIC_CAPTCHA_API_URL,
  PUBLIC_FB_APPID,
  PUBLIC_GOOGLE_CLIENTID,
} from '@account-service/core/envs'
import { ConnectorProvider } from '@axieinfinity/connect'
import { ErrorBoundary } from '@sentry/nextjs'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { Work_Sans } from 'next/font/google'
import Head from 'next/head'
import React from 'react'
import type { NextPageWithLayout } from 'types/next'

import Background from '~/components/Background'
import WentWrong from '~/components/WentWrong'
import { CAPTCHA_REGISTER_ENDPOINT } from '~/constants'

const AccountServiceProvider = dynamic(
  () => import('~/components/AccountServiceProvider'),
  {
    ssr: false,
  },
)

const workSans = Work_Sans({
  subsets: ['latin'],
  variable: '--font-sans',
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
})

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  return (
    <>
      {/* @ts-ignore */}
      <style jsx global>{`
        :root {
          --font-sans: ${workSans.style.fontFamily};
        }
      `}</style>
      <Head>
        <meta name="author" content="skymavis.com" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, interactive-widget=overlays-content"
        />
        <meta
          name="description"
          content="The one and only account you need to sign in and explore hundred of dApps and Games in Ronin ecosystem."
        />
        <meta property="og:title" content="Ronin Account" />
        <meta property="og:site_name" content="Ronin Account" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/preview.png" />

        <link rel="shortcut icon" href="/favicon.ico" />
      </Head>
      <ErrorBoundary
        fallback={
          <Background>
            <WentWrong />
          </Background>
        }
      >
        <AccountServiceProvider
          configs={{
            facebookAppId: PUBLIC_FB_APPID,
            googleAppId: PUBLIC_GOOGLE_CLIENTID,
            ssoServer: PUBLIC_AUTH_SERVER_URL,
            geetestServer: CAPTCHA_REGISTER_ENDPOINT,
            appleAppId: 'com.skymavis.accounts.service',
          }}
        >
          <CaptchaProvider
            configs={{
              captchaKey: PUBLIC_CAPTCHA_API_KEY,
              captchaUrl: PUBLIC_CAPTCHA_API_URL,
            }}
          >
            <ConnectorProvider>
              <Component {...pageProps} />
            </ConnectorProvider>
          </CaptchaProvider>
        </AccountServiceProvider>
      </ErrorBoundary>
    </>
  )
}
