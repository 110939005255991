import { randomUUID } from '@account-service/core'

import { LOGIN_STORE_KEY, USER_ID } from '~/constants'

export function getStorageItem(key: string) {
  const item = localStorage.getItem(key)
  if (item !== null) {
    try {
      return JSON.parse(item)
    } catch {
      // ignore error
    }
  }
}

export function setStorageItem(key: string, value: any) {
  if (value !== undefined) {
    const toStore = JSON.stringify(value)
    localStorage.setItem(key, toStore)
    return JSON.parse(toStore)
  }
}

export function removeStorageItem(key: string) {
  localStorage.removeItem(key)
}

export const getWalletconnectData = () => {
  const wcKeys = [
    'wc@2:core:0.3//keychain',
    'wc@2:universal_provider:/namespaces',
    'wc@2:client:0.3//session',
    'wc@2:ethereum_provider:/chainId',
  ]

  const wcData = wcKeys.reduce((acc, key) => {
    const value = localStorage.getItem(key)
    if (value) acc[key] = value
    return acc
  }, {} as Record<string, string>)

  return wcData ? JSON.stringify(wcData) : undefined
}

export const clearWalletconnectV2Storage = () => {
  const walletconnectV2Keys = Object.keys(localStorage).filter(key =>
    key.startsWith('wc@2'),
  )

  // Support v1
  removeStorageItem('walletconnect')
  walletconnectV2Keys.map(key => removeStorageItem(key))

  // Remove Index
  indexedDB.deleteDatabase('WALLET_CONNECT_V2_INDEXED_DB')
}

export const getSessionId = () => {
  const loginStore = getStorageItem(LOGIN_STORE_KEY)
  const sessionId = loginStore?.state?.sessionId
  return sessionId ?? randomUUID()
}

export const getClientId = () => {
  const loginStore = getStorageItem(LOGIN_STORE_KEY)
  const clientId = loginStore?.state?.ssoClient?.client_id
  return clientId
}

export const getLoginChallenge = () => {
  const loginStore = getStorageItem(LOGIN_STORE_KEY)
  const sessionId = loginStore?.state?.loginChallenge ?? ''
  return sessionId
}

export const setStorageUserId = (userId: string) =>
  setStorageItem(USER_ID, userId)

export const getUserId = () => {
  const loginStore = getStorageItem(LOGIN_STORE_KEY)
  const userId = loginStore?.state?.userId ?? ''
  return userId
}
